import React, { useState, useRef } from "react"
import { Link } from "gatsby"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { Button } from "../buttons"

import { handleSubmit } from '../../custom/gtag-form'

import setaDown from "../../images/marcarConsulta/setaDown.svg"
import checkbox from "../../images/marcarConsulta/caixa.svg"
import checked from "../../images/marcarConsulta/visto_caixa.svg"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker"
import pt from "date-fns/locale/pt"
registerLocale("pt", pt)


const axios = require("axios")

const FormServicos = (props) => {
  const form = useRef()

  const [startDate, setStartDate] = useState(null)
  const [response, setResponse] = useState("")
  var checker = []

  function doSubmit(e) {
    checkValues()

    if (checker.length < 1) {
      e.preventDefault()
      setResponse("")
      var formData = new FormData()
      formData.append("name", document.querySelector("#nameConsulta").value)
      formData.append("email", document.querySelector("#emailConsulta").value)
      formData.append("phone", document.querySelector("#phoneConsulta").value)
      formData.append(
        "clinica",
        document.querySelector("#clinicConsulta").value
      )
      formData.append("data", document.querySelector("#dateConsulta").value)
      formData.append("hora", document.querySelector("#timeConsulta").value)
      formData.append(
        "primeiraConsulta",
        document.querySelector('input[name="primeiraConsulta"]:checked').value
      )
      formData.append("motivo", document.querySelector("#motiveConsulta").value)
      formData.append("consulta", true)
      formData.append("newsletter", newsCheck ? true : false)

      axios
        .post("https://carreiradentalclinic.pt/form-contact.php", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data; charset=UTF-8",
          },
        })
        .then(res =>
          res.data === "success"
            ? success()
            : setResponse(res.data)
        )
    } else {
      e.preventDefault()
      setResponse("Faltam os seguintes campos: " + checker + ".")
    }
  }

  const success = () => {
    setResponse("Pedido de consulta enviado com sucesso, Entraremos em contacto consigo o mais breve possível. Obrigado")
    if (typeof window !== "undefined") {
      var clinicTosend = handleSubmit(document.querySelector("#clinicConsulta").value)

      window.gtag('event', 'form_consulta', {
        form: 'Formulário Marcação Consulta',
        especialidade: document.querySelector("#motiveConsulta").value,
        clinica: clinicTosend
      })
    }
    form.current.reset()

  }

  function checkValues() {
    checker = []
    let checkerTemp = []
    if (!document.querySelector("#nameConsulta").value) {
      checkerTemp.push("nome")
    }
    if (!document.querySelector("#emailConsulta").value) {
      checkerTemp.push("email")
    }
    if (document.querySelector("#clinicConsulta").value === "true") {
      checkerTemp.push("clínica")
    }
    if (!document.querySelector("#dateConsulta").value) {
      checkerTemp.push("data")
    }
    if (document.querySelector("#timeConsulta").value === "true") {
      checkerTemp.push("hora")
    }
    if (!document.querySelector('input[name="primeiraConsulta"]:checked')) {
      checkerTemp.push("primeira consulta ou não")
    }
    if (document.querySelector("#motiveConsulta").value === "true") {
      checkerTemp.push("motivo")
    }
    if (!polCheck) {
      if (checkerTemp === []) {
        checkerTemp.push("aceitar os nossos termos e políticas")
      } else {
        checkerTemp.push("e aceitar os nossos termos e políticas")
      }
    }
    checker = checkerTemp
  }

  const [simCheck, setSimCheck] = useState(false)
  const [naoCheck, setNaoCheck] = useState(false)
  const [newsCheck, setNewsCheck] = useState(false)
  const [polCheck, setPolCheck] = useState(false)



  function checkNao() {
    document.querySelector("#radioSim").checked = false
    document.querySelector("#radioNao").checked = true
    setSimCheck(false)
    setNaoCheck(true)
  }
  function checkSim() {
    document.querySelector("#radioSim").checked = true
    document.querySelector("#radioNao").checked = false
    setSimCheck(true)
    setNaoCheck(false)
  }

  function checkNewsletter() {
    document.querySelector("#newsletter").checked = !document.querySelector(
      "#newsletter"
    ).checked
    setNewsCheck(!newsCheck)
  }
  function checkPolitica() {
    document.querySelector("#politica").checked = !document.querySelector(
      "#politica"
    ).checked
    setPolCheck(!polCheck)
  }

  return (
    <FormStyled>
      <div className='wrapper' id='marcar'>
        <p className={font.rB + " title"}>
          PEDIDO DE CONSULTA
          <span className="underline" />
        </p>


        <form iv className="form" ref={form}>
          <div className='input-box' id='name'>
            <label className={font.rSB}>Nome *</label>
            <input
              type="text"
              name="nome"
              className={font.rM}
              id="nameConsulta"
            />
          </div>

          <div className='input-box' id='email'>
            <label className={font.rSB} >Email *</label>
            <input
              type="text"
              name="email"
              className={font.rM}
              id="emailConsulta"
            />
          </div>

          <div className='input-box' id='telefone'>
            <label className={font.rSB}>Telefone *</label>
            <input
              type="text"
              name="telefone"
              className={font.rM}
              id="phoneConsulta"
            />
          </div>

          <div className='input-box' id='clinica'>
            <label className={font.rSB}>Clínica *</label>
            <select
              name="clinica"
              className={font.rM}
              id="clinicConsulta"
              defaultValue="default"
            >
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <option value="default" disabled hidden />
              <option value="porto@carreiradentalclinic.pt">Porto</option>
              <option value="aveiro@carreiradentalclinic.pt">Aveiro</option>
              <option value="agueda@carreiradentalclinic.pt">Águeda</option>
              <option value="viseu@carreiradentalclinic.pt">Viseu</option>
              <option value="oaz@carreiradentalclinic.pt">Oliveira de Azeméis</option>
            </select>
            <img src={setaDown} alt="Selecionar" className="setaDown" />
          </div>

          <div className='input-box' id='dia'>
            <label className={font.rSB}>Dia *</label>
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              locale="pt"
              dateFormat="dd/MM/yyyy"
              className={font.rM}
              id="dateConsulta"
              name="dia"
            />
            <img src={setaDown} alt="Selecionar" className="setaDown" />
          </div>

          <div className='input-box' id='hora'>
            <label className={font.rSB}>Hora *</label>
            <select
              name="hora"
              className={font.rM}
              id="timeConsulta"
              defaultValue="default"
            >
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <option disabled value="default" hidden />
              <option value="10:00-11:00">10:00-11:00</option>
              <option value="11:00-12:00">11:00-12:00</option>
              <option value="12:00-13:00">12:00-13:00</option>
              <option value="14:00-15:00">14:00-15:00</option>
              <option value="15:00-16:00">15:00-16:00</option>
              <option value="16:00-17:00">16:00-17:00</option>
              <option value="17:00-18:00">17:00-18:00</option>
              <option value="18:00-19:00">18:00-19:00</option>
              <option value="19:00-20:00">19:00-20:00</option>
            </select>
            <img src={setaDown} alt="Selecionar" className="setaDown" />
          </div>

          <div className='input-box' id='option'>
            <label className={font.rSB}>1ª CONSULTA*</label>
            <div className="radioOptions">
              <label className="radioOption">

                <input
                  type="radio"
                  name="primeiraConsulta"
                  value="sim"
                  id="radioSim"
                  onClick={checkSim}
                />

                <p className={font.rSB}>Sim</p>
                <span className="checkmark">
                  <img
                    src={simCheck ? checked : checkbox}
                    alt="Caixa do sim"
                  />
                </span>
              </label>
              <label className="radioOption">

                <input
                  type="radio"
                  name="primeiraConsulta"
                  value="nao"
                  id="radioNao"
                  onClick={checkNao}
                />
                <p className={font.rSB}>Não</p>
                <span className="checkmark">
                  <img
                    src={naoCheck ? checked : checkbox}
                    alt="Caixa do não"
                  />
                </span>
              </label>
            </div>

          </div>

          <div className='input-box' id='especialidade'>
            <label className={font.rSB}>especialidade *</label>
            <select
              name="motivo"
              className={font.rM}
              id="motiveConsulta"
              defaultValue="default"
            >
              <option disabled hidden value="default" />
              <option value="Implantologia">Implantologia</option>
              <option value="Cirurgia Oral">Cirurgia Oral</option>
              <option value="Ortodontia">Ortodontia</option>
              <option value="Reabilitação Estética">Reabilitação Estética</option>
              <option value="Periodontologia">Periodontologia</option>
              <option value="Prostodontia">Prostodontia</option>
              <option value="Dentisteria">Dentisteria</option>
              <option value="Odontopediatria">Odontopediatria</option>
              <option value="Higiene Oral">Higiene Oral</option>
              <option value="Harmonização Facial">
                Harmonização Facial
              </option>
              <option value="Outro">Outro</option>
            </select>
            <img src={setaDown} alt="Selecionar" className="setaDowne" />
          </div>

          <div className='input-box' id='news'>
            <div className="radioNewsAll">
              <label className="radioNews">
                <span className="checkmark">
                  <img
                    src={newsCheck ? checked : checkbox}
                    alt="Newsletter"
                  />
                </span>
                <input
                  type="checkbox"
                  name="newsletter"
                  value="newsletter"
                  id="newsletter"
                  onClick={checkNewsletter}
                />
                <p className={font.rSB}>Quero receber notícias de saúde oral no meu email</p>
              </label>
              <label className="radioNews">
                <span className="checkmark">
                  <img
                    src={polCheck ? checked : checkbox}
                    alt="Política de Privacidade"
                  />
                </span>
                <input
                  type="checkbox"
                  name="politica"
                  value="politica"
                  id="politica"
                  onClick={checkPolitica}
                />
                <p className={font.rSB + " textflex"}>
                  Aceito os{" "}
                  <span style={{ color: "#b7a99a" }}>
                    <Link to="/politica_de_privacidade">
                      &nbsp;termos de política e privacidade*
                    </Link>
                  </span>
                </p>
              </label>
            </div>

          </div>

          <div className='input-box' id='info'>
            <span className={font.rSB}><b>* CAMPOS DE PREENCHIMENTO OBRIGATÓRIO</b></span><br /><br />
            <span className={font.rSB}>Este formulário de contacto corresponde ao procedimento normal de envio de um email directamente para a nossa caixa de correio. Os seus dados não são armazenados nem utilizados para qualquer outro fim.</span>
          </div>

          <div className='btn' type="submit" id='button'>
            <Button styleless className="btnMarcar" onClick={doSubmit}>
              <p className={font.rSB}>MARCAR</p>
            </Button>
          </div>

          <div className='input-box' id="response">
            <div className='response'>
              <p className={font.rSBI + " response"}><b>{response}</b></p>
            </div>
          </div>

        </form>
      </div>
    </FormStyled>
  )
}

export default FormServicos

const FormStyled = styled.div`
    background-color: rgb(238, 234, 230);

    .wrapper{
        max-width:1920px;
        margin-bottom: 6vw;
        padding: clamp(30px,15vw,30px) 5%;
        color: rgb(35, 31, 32);

        .title {
          font-size: 30px;
          
          @media only screen and (max-width: 768px) {
            font-size: calc(20px + 8 * (100vw - 320px) / 448);
          }

          line-height: 1.15em;
          letter-spacing: 0.15em;
          color: #231f20;
          position: relative;
          margin: 0 auto;
          max-width: 1000px;
          margin-bottom: 6%;

          .underline {
            position: absolute;
            bottom: -0.3em;
            left: 0;
            width: 1.4em;
            height: 5px;
            background-color: #b7a99a;
          }
        }
      
    h2{
        max-width:1000px;
        margin:0 auto;
        text-transform:uppercase;
        font-weight:500;
        font-size: 1.8rem;
        letter-spacing: 0.2rem;
        margin-bottom:3rem;
    }
    
    form{
        display:grid;
        grid-template-areas: 'name email' 'telefone clinica' 'dia hora' 'option especialidade' 'news button' 'info info' 'response response';
        grid-template-columns: repeat(3,1fr);
        grid-gap: 30px;
        max-width:1000px;
        margin:0 auto;
        font-size: calc(16px + 4 * (100vw - 769px) / 1151);
        
        @media only screen and (max-width: 900px){
          grid-template-areas: 'name' 'email' 'telefone' 'clinica' 'dia' 'hora' 'especialidade' 'option' 'news' 'info' 'button' 'response';
          grid-template-columns: 1fr;
          grid-gap: 2rem;
        }
        

        #name{
            grid-area:name;
        }
        #empresa{
            grid-area:empresa;
        }
        #email{
            grid-area:email;
        }
        #contact{
            grid-area:contact;
        }
        #message{
            grid-area:message;
        }
        #info{
            grid-area:info;
        }
        #button{
          grid-area:button;
        }
        #response{
            grid-area:response;
        }

        .input-box{
          position:relative;
          top: -15px;
          left: 0;
          font-size: 12px;

          .setaDowne {
            position: absolute;
            width: 16px;

            @media only screen and (max-width: 768px) {
              width: calc(15px + 5 * (100vw - 320px) / 448);
              top: 65%;
            }
            
            right: 10px;
            top: 30%;
            transform: translateY(-20%);
            pointer-events: none;
            cursor: pointer;
          }

          .setaDown {
            position: absolute;
            width: 16px;

            @media only screen and (max-width: 768px) {
              width: calc(15px + 5 * (100vw - 320px) / 448);
            }
            
            right: 10px;
            top: 64%;
            transform: translateY(-20%);
            pointer-events: none;
            cursor: pointer;
          }
            
          .label {
            position: absolute;
            top: -50%;
            left: 0%;
          }
            
          input, select {
            z-index: 1;
            padding: 0 3%;
            height: 30px;
            border: 1px solid rgb(183, 169, 154);
            outline: none;
            background-color: rgb(238, 234, 230);
            width:100%;
            box-sizing:border-box;
          }

          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: '';

            option {
              font-size: 18px;
            }
          }

          label, input{
            text-transform:uppercase;
            font-size: 15px;
            color: rgb(35, 31, 32);
            font-weight: 300;
          }

          .react-datepicker-wrapper {
            width: 100%;
          }

          .react-datepicker__tab-loop {
            position: relative;
          }

          .react-datepicker-popper {
            position: absolute;
            top: 0;
            transform: translateY(-65%) !important;
          }

          .react-datepicker__triangle {
            display: none;
          }
          
          .radioOptions {
            display: flex;

            .radioOption {
              display: flex;
              gap: 5%;
              align-items: center;
              color: #808080;
              cursor: pointer;
              margin-top: 1%;

              .checkmark {
                width: 30px;
                height: 30px;
                margin-right: 20px;
                cursor: pointer;

                img {
                  width: 20px;
                  height: 30px;
                  padding: 0%;
                  margin: 0%;
                }
                
              }
            }
          }

          .radioNewsAll {

            .radioNews {
              display: flex;
              align-items: center;
              color: #808080;
              cursor: pointer;
              margin-top: 5%;
              text-transform: none;
              font-size: 15px;

              .checkmark {
                width: 30px;
                height: 30px;
                margin-right: 20px;
                cursor: pointer;

                img {
                  width: 20px;
                  height: 30px;
                  padding: 0%;
                  margin: 0%;
                }
              }

              .textflex {
                span {
                  a {
                    display: inherit;
                  }
                }
              }
            }
          }
        

        
          #info{
              span{
                  font-size:0.8rem;
              }
          }

          .response {
            margin-top: 15px;
            position: relative;

           

          }
        }
        
          .btn{

            .btnMarcar {
              width: calc(140px + 60 * (100vw - 320px) / 1600);
              height: calc(30px + 24 * (100vw - 320px) / 1600);
              font-size: calc(14px + 6 * (100vw - 320px) / 1600);

              @media only screen and (min-width: 1920px) {
                font-size: 20px;
                width: 200px;
                height: 54px;
              }
              
              letter-spacing: 0.1em;
              background-color: #b7a99a;
              color: #ffffff;
              text-align: center;
              line-height: 0;
              z-index: 1;
              -webkit-touch-callout: none;
              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              user-select: none;
              cursor: pointer;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin: 0 auto;
            }

            .btn img {
              height: 50%;
            }

          }
          

        
      }
    }
  }
`